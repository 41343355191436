import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { Article, Hero, HeroArticle, Page, Section } from "components";
import { FormContact } from "containers";

const TemplateServiceCategory = ({
  data: {
    service: {
      body,
      frontmatter: {
        category,
        description,
        form,
        header,
        hero,
        image,
        page,
        seo,
        tags,
        title,
        type,
      },
    },
  },
  location: { href, origin, pathname },
}) => {
  const categoryLowerCase = category.toLowerCase();
  const categoryTags = tags && tags.map((tag) => ` ${tag.toLowerCase()}`);
  const serviceLowerCase = type.toLowerCase();

  const seoOptions = {
    title: `${category} | ${type} Services`,
    description,
    ...seo,
    openGraph: {
      title,
      url: href,
      ...seo?.openGraph,
      images: [
        {
          url: `${origin}${image?.childImageSharp?.fluid?.src}`,
          width: 1080,
          height: 720,
          alt: title,
        },
      ],
    },
  };

  const pageOptions = {
    header: {
      color: "default",
    },
    ...page,
  };

  const headerOptions = {
    buttons: [
      {
        label: `${type} Services`,
        path: `/${type.split(" ").join("-").toLowerCase()}/`,
      },
    ],
    className: "secondary",
    heading: title,
    image,
    ...header,
  };

  // TODO: add hero options to contact form container to avoid this messy config
  const heroOptions = {
    bgcolor: "grey.50",
    context:
      form && form.context
        ? form.context
        : `Clean Commercial provides professional ${serviceLowerCase} services for ${categoryLowerCase} in Las Vegas, Henderson or other parts of Southen Nevada.${
            tags &&
            ` High quality ${serviceLowerCase} work for ${categoryTags}, and much more by skilled artisans, qualified engineers, licensed contractors, and other experienced workers.`
          } Fill out your name, email and a few details about your ${categoryLowerCase} needs, and we'll handle the rest.`,
    heading:
      form && form.heading
        ? form.heading
        : `Custom, high quality ${categoryLowerCase} and other ${serviceLowerCase} needs`,
    minHeight: "none",
    ...hero,
  };

  const formOptions = {
    buttonCopy: `Get ${serviceLowerCase} services`,
    referralProps: {
      category,
      page: pathname,
      type,
    },
    ...form,
  };

  // TODO: move all instances of renderArticle into a reusable component or hook
  const renderArticle = (pageType) => {
    switch (pageType) {
      case "media":
        return null;
      default:
        return (
          <Section>
            <Article content={body} />
          </Section>
        );
    }
  };

  return (
    <Page options={pageOptions} seo={seoOptions}>
      <HeroArticle {...headerOptions} />
      {renderArticle(pageOptions.pageType)}
      <Hero {...heroOptions}>
        {!formOptions.isHidden && <FormContact {...formOptions} />}
      </Hero>
    </Page>
  );
};

TemplateServiceCategory.propTypes = {
  data: PropTypes.shape({
    service: PropTypes.shape({
      body: PropTypes.string,
      fields: PropTypes.shape({
        slug: PropTypes.string,
      }),
      frontmatter: PropTypes.shape({
        category: PropTypes.string,
        description: PropTypes.string,
        form: PropTypes.shape({
          buttonCopy: PropTypes.string,
          context: PropTypes.string,
          heading: PropTypes.string,
          isHidden: PropTypes.bool,
        }),
        header: PropTypes.shape({
          heading: PropTypes.string,
        }),
        image: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              aspectRatio: PropTypes.number,
              base64: PropTypes.string,
              src: PropTypes.string,
              srcSet: PropTypes.string,
              sizes: PropTypes.string,
            }),
          }),
        }),
        page: PropTypes.shape({
          header: PropTypes.shape({
            color: PropTypes.string,
          }),
          pageType: PropTypes.string,
        }),
        seo: PropTypes.shape({
          title: PropTypes.string,
        }),
        tags: PropTypes.arrayOf(PropTypes.string),
        title: PropTypes.string,
        type: PropTypes.string,
      }),
    }),
  }),
};

export const serviceByCategorySlugQuery = graphql`
  query ServiceByCategorySlug($slug: String!) {
    service: mdx(fields: { slug: { eq: $slug } }) {
      body
      fields {
        slug
      }
      frontmatter {
        category
        description
        form {
          buttonCopy
        }
        image {
          childImageSharp {
            fluid(maxWidth: 1280, maxHeight: 720, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        page {
          pageType
        }
        tags
        title
        type
      }
    }
  }
`;

export default TemplateServiceCategory;
